import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "4-enjoy-useauth"
    }}>{`4. Enjoy useAuth`}</h2>
    <p>{`You're ready to use useAuth for authentication in your React app.`}</p>
    <p>{`Here's a login button for example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const Login = () => {
    const { isAuthenticated, login, logout } = useAuth()

    if (isAuthenticated()) {
        return <Button onClick={logout}>Logout</Button>
    } else {
        return <Button onClick={login}>Login</Button>
    }
}
`}</code></pre>
    <p><inlineCode parentName="p">{`isAuthenticated`}</inlineCode>{` is a method that checks if the user's cookie is still valid.`}</p>
    <p><inlineCode parentName="p">{`login`}</inlineCode>{` and `}<inlineCode parentName="p">{`logout`}</inlineCode>{` trigger their respective actions.`}</p>
    <p>{`You can even say hello to your users:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// src/pages/index.js

const IndexPage = () => {
  const { isAuthenticated, user } = useAuth()

  return (
    <Layout>
      <SEO title="Home" />
      <h1>Hi {isAuthenticated() ? user.name : "people"}</h1>
  )
}
`}</code></pre>
    <p>{`Check `}<inlineCode parentName="p">{`isAuthenticated`}</inlineCode>{` then use the user object. Simple as that.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      